<template>
    <v-container>
        <h1 align="center">Tarife</h1>
        <TarifeTable></TarifeTable>
        <v-btn to="/new-tarif/" block color="primary">Neuer Tarif</v-btn>
    </v-container>
</template>

<script>
    import TarifeTable from '@/components/TarifeTable';

    export default {
        name: 'Tarife',
        components: {TarifeTable},
    }
</script>

<style scoped>

</style>
