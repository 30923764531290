<template>
    <div>
        <v-data-table
                :headers="headers"
                :items="tarife"
                hide-default-footer
                disable-pagination
        >
            <template v-slot:item.actions="{ item }">
                <v-icon @click="tarifEdit(item.id)">mdi-pencil</v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    export default {
        name: "TarifeTable",
        computed: {
            tarife: {
                get() {
                    return this.$store.$db().model('tarif').query().withAll().all()
                }
            }
        },
        data() {
            return {
                headers: [
                    {
                        text: 'Anbieter',
                        value: 'anbieter.name'
                    },
                    {
                        text: 'Name',
                        value: 'name'
                    },
                    {
                        text: 'Grundpreis',
                        value: 'grundpreis'
                    },
                    {
                        text: 'Bandbreite Down',
                        value: 'bandbreite_down'
                    },
                    {
                        text: 'Bandbreite Up',
                        value: 'bandbreite_up'
                    },
                    {
                        text: 'Laufzeit',
                        value: 'vertragslaufzeit'
                    },
                    {
                        text: 'Installation',
                        value: 'installations_preis'
                    },
                    {
                        text: 'Flat',
                        value: "is_flat"
                    },
                    {
                        text: 'DSL',
                        value: "is_dsl"
                    },
                    {
                        text: 'Cable',
                        value: "is_cable"
                    },
                    {
                        text: 'Fiber',
                        value: "is_fiber"
                    },
                    {
                        text: 'Phone Flat',
                        value: "has_phone_flat"
                    },
                    {
                        text: 'TV',
                        value: "has_tv"
                    },
                    {
                        text: 'Handy Flat',
                        value: "has_handy_flat"
                    },
                    { text: 'Actions', value: 'actions', sortable: false },
                ]
            }
        },
        methods: {
            tarifEdit (id) {
                this.$router.push({path: `/edit-tarif/${id}`})
            }
        }
    }
</script>

<style scoped>

</style>
